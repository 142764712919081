@import "styles/mixins.scss";
@import "styles/colors.module.scss";

.truck-container {
    margin: rem(20);
    display: flex;
    flex-direction: column;
    gap: rem(10);
    height: calc(100% - rem(40)) !important;
    position: relative;

    &.container-dragged {
        &::before {
            content: 'Drop files here';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            border: 2px dashed $primary;
            background-color: rgba($color: #fff, $alpha: .8);
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: rem(30);
            color: $primary;
        }
    }

    .header-container {
        display: flex;
        align-items: center;
        gap: rem(10);

        svg {
            color: $text-alternate;
            font-size: rem(16);
            cursor: pointer;
            transition: color .3s;

            &:hover {
                color: $primary;
            }
        }

        p {
            font-size: rem(20);
            font-weight: 600;
            line-height: rem(24);
            letter-spacing: 0.15px;
        }
    }

    .content-container {
        display: flex;
        gap: rem(10);
        margin: rem(10) 0;
        height: 100%;

        .left-content-container,
        .right-content-container {
            flex-basis: 0;
            flex: 1 1 0px;
        }

        .vertical-divider {
            content: '';
            width: rem(1);
            background: #F5F5F5;
        }

        .left-content-container {
            display: flex;
            flex-direction: column;
            gap: rem(10);

            .detail-container {
                display: flex;
                gap: rem(15);

                &.detail-container-row {
                    flex-direction: column;
                    gap: rem(7);

                    .detail-header {
                        width: auto;
                    }
                }

                .detail-header {
                    display: flex;
                    align-items: center;
                    gap: rem(3);
                    width: 30%;

                    p {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: rem(16);
                        font-weight: 500;
                    }

                    svg {
                        width: rem(24);
                        font-size: rem(12);
                        color: $text-alternate;
                    }
                }

                .detail-value {
                    font-size: rem(16);
                    font-weight: 400;
                    line-height: rem(24);
                    display: flex;
                    gap: rem(5);

                    .extra {
                        color: $text-alternate;
                        font-size: rem(16);
                        font-style: italic;
                        font-weight: 400;
                    }
                }

                .comment-input div {
                    height: auto;
                }
            }

            .attachments-container {
                .attachments-amount {
                    color: rgba(0, 42, 76, 0.4);
                    line-height: rem(28);
                }

                .inline-input-wrapper {
                    height: auto;
                    border: none !important;

                    svg {
                        color: $primary;
                        transition: color .3s;
                        height: rem(20);
                        width: rem(20);
                    }

                    &:hover svg {
                        color: darken($primary, 10);
                    }
                }

                .inline-input-container {
                    padding-bottom: 0;
                }

                .attachments-input-wrapper {
                    height: auto;
                    border: 1px dashed #475159 !important;
                    border-radius: 4px;

                    div {
                        flex-direction: row-reverse;
                        justify-content: flex-end;
                        gap: rem(10);
                        padding: rem(12) rem(16);
                        color: #80929D;

                        p {
                            font-size: rem(14);
                            font-weight: 400;
                            line-height: 1;
                        }

                        svg {
                            font-size: rem(20);
                        }

                        &:hover {
                            color: $primary;
                        }
                    }

                    &:hover {
                        border-color: $primary !important;
                    }
                }

                .attachments-input-container {
                    padding-bottom: 0;
                }

                .scrollable-attachments {
                    display: flex;
                    gap: rem(10);
                    height: fit-content;
                }

                .attachment {
                    border-radius: rem(4);
                    padding: rem(8);
                    position: relative;
                    width: rem(100);
                    height: rem(60);
                    min-width: rem(100);
                    min-height: rem(60);
                    background-color: rgba(66, 165, 245, 0.1);
                    border: 1px solid rgba(0, 0, 0, 0.07);
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    gap: rem(6);

                    .attachment-information {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        height: 100%;

                        div {
                            border-radius: rem(4);
                            padding: 0 rem(5);
                            display: flex;
                            background: rgba(66, 165, 245, 0.5);
                            text-transform: uppercase;
                            color: $white;
                            font-weight: 500;
                            font-size: rem(12);
                            line-height: rem(18);
                            width: fit-content;
                        }

                        p {
                            width: 100%;
                            max-height: rem(32);
                            font-weight: 400;
                            font-size: rem(14);
                            line-height: rem(16);
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }

                    .attachment-actions {
                        display: none;
                        margin-bottom: rem(-2);
                        margin-left: rem(-2);
                        gap: rem(4);

                        button {
                            padding: rem(4);
                        }

                        svg {
                            color: #737577;
                            font-size: rem(14);
                        }
                    }

                    img {
                        display: none;
                    }

                    &:hover {
                        .attachment-information div {
                            display: none;
                        }

                        .attachment-actions {
                            display: flex;
                        }
                    }
                }

                .image {
                    background-color: rgba(125, 189, 54, 0.1);

                    .attachment-information div {
                        background: rgba(125, 189, 54, 0.5);
                    }
                }

                .pdf {
                    background-color: rgba(227, 0, 0, 0.1);

                    .attachment-information div {
                        background: rgba(227, 0, 0, 0.5);
                    }
                }
            }
        }

        .right-content-container {
            display: flex;
            flex-direction: column;
            gap: rem(15);
            margin: 0 rem(15);

            .truck-content-header {
                display: flex;
                flex-direction: column;
                gap: rem(7);

                p {
                    line-height: 1;

                    &:first-of-type {
                        font-size: rem(16);
                        font-weight: 600;
                    }

                    &:nth-of-type(2) {
                        color: $text-alternate;
                        font-size: rem(14);
                        font-weight: 400;
                    }
                }
            }

            .truck-content-wrapper {
                display: flex;
                gap: rem(7);
                row-gap: rem(7);
                flex-wrap: wrap;
                align-items: baseline;

                &.no-content {
                    margin: auto;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    padding: rem(35);

                    h6 {
                        font-style: italic;
                        color: rgba(0, 0, 0, 0.24);
                        font-size: rem(13);
                    }
                }

                .package-display-container {
                    border-radius: rem(5);
                    border: 1px solid #6868AC;
                    background: #F4F5FF;
                    display: flex;
                    padding: rem(10);
                    flex-direction: column;
                    align-items: flex-start;
                    gap: rem(7);
                    align-self: stretch;
                    width: calc(50% - rem(4));
                    margin-bottom: rem(3);
                    min-width: rem(330);

                    .package-display-header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        align-self: stretch;

                        svg {
                            cursor: pointer;
                        }

                        .package-display-header-info {
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            gap: rem(5);

                            &:hover {
                                p {
                                    border-bottom: 1px solid transparent;
                                }
                            }

                            svg:first-of-type {
                                color: #6868AC;
                                font-size: rem(14);
                            }

                            svg:last-of-type {
                                color: $text-alternate;
                                font-size: rem(11);
                            }

                            p {
                                color: #6868AC;
                                font-size: rem(14);
                                font-weight: 500;
                                border-bottom: 1px solid #6868AC;
                                transition: border .3s;
                                line-height: .8;
                            }
                        }
                    }

                    .package-display-subtext {
                        color: $text-alternate;
                        font-size: rem(14);
                        font-weight: 400;
                    }

                    .package-display-content-container {
                        display: flex;
                        align-items: flex-start;
                        gap: rem(7);
                        align-self: stretch;
                        flex-wrap: wrap;
                    }
                }
            }
        }
    }
}
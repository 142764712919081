@import "styles/mixins.scss";
@import "styles/colors.module.scss";

.stacking-content-container {
    display: inline-flex;
    padding: rem(5) rem(10);
    align-items: center;
    gap: rem(7);
    border-radius: rem(4);
    border: 1px solid transparent;
    transition: all .1s;
    flex-shrink: 0;

    p {
        font-size: rem(15);
        font-weight: 400;
        line-height: 1;
        text-transform: capitalize;
        user-select: none;
    }

    svg {
        font-size: rem(13);
    }

    svg,
    &.selectable {
        cursor: pointer;
    }

    &.light {
        color: $text-primary;
        background: rgba(66, 165, 245, 0.15);

        svg:hover {
            color: rgba(0, 0, 0, .3);
        }

        &.selected {
            border: 1px solid rgba(66, 165, 245, 0.45);
            background: rgba(66, 165, 245, 0.35);
        }
    }

    &.dark {
        color: $white;
        background: #6868AC;

        svg:hover {
            color: rgba(255, 255, 255, .5);
        }

        &.selected {
            border: 1px solid rgba(104, 104, 172, 0.75);
            background: rgba(104, 104, 172, 0.65);
        }
    }
}
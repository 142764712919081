@import "styles/mixins.scss";
@import "styles/colors.module.scss";

.truck-item-container {
    display: flex;
    padding: rem(12);
    align-items: flex-start;
    gap: rem(15);
    align-self: stretch;
    border-radius: 15px;
    border: 1px solid $tab-border-color;
    margin: rem(3.75) rem(7.5);
    cursor: pointer;

    .truck-item-content-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        flex: 1 0 0;
        height: 100%;

        .truck-item-header-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: rem(10);
            width: 100%;

            .truck-item-name {
                flex-basis: content;
                color: $text-primary;
                font-size: rem(16);
                font-weight: 600;
                line-height: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .truck-item-updated-on {
                flex: 0 1 content;
                color: $text-alternate;
                font-size: rem(12);
                font-weight: 400;
                line-height: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .truck-item-detail-container {
            display: flex;
            align-items: flex-end;
            gap: rem(15);
            align-self: stretch;

            .truck-item-description {
                flex: 1 0 0;
                color: $text-alternate;
                font-size: rem(14);
                font-weight: 400;
                line-height: rem(16);
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .truck-item-state {
                width: rem(20);
                height: rem(20);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                text-transform: uppercase;

                span {
                    font-size: rem(12);
                    font-weight: 700;
                }
            }
        }
    }
}
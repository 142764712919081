@import "styles/mixins.scss";
@import "styles/colors.module.scss";

.dialog-paper {
    width: 90% !important;
    padding: 0 !important;
    max-width: rem(2000) !important;
    min-height: calc(100% - rem(64));
}

.icon-style {
    font-size: 1.2rem !important;
    color: $icon-dark;
}

.create-issue-input-container {
    background-color: $background;
    border-radius: rem(4);

    div:first-child {
        height: auto;
    }

    fieldset {
        border-color: rgba(66, 164, 245, .2) !important;
    }
}

.title-container {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    position: relative !important;
    z-index: 0 !important;
    padding: rem(15) !important;
    min-height: rem(100);
    color: white !important;

    .main-content-title {
        font-size: rem(28);
        line-height: rem(33);
        color: white !important;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 2px;
        left: 0;
        right: 0;
        z-index: -1;
        background-color: rgba(0, 0, 0, 0.4);
    }

    span {
        color: $text-third !important;
    }

    h2 {
        margin-right: 1rem;
        color: $white;
    }

    .issue-information {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;

        .title-input {
            color: #fff;
            font-size: rem(28);
        }

        h1,
        h6 {
            width: 100%;
            color: $white;
        }

        h1 {
            font-size: rem(28);
            line-height: rem(33);
        }

        h6 {
            font-weight: 400;
            font-size: rem(16);
            line-height: rem(19);

            span {
                font-weight: 700;
            }
        }
    }

    .state-selector {
        background-color: $white;
        display: flex !important;
        border-radius: rem(4);

        p {
            margin: 0;
        }
    }
}

.content-container {
    display: flex;
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden !important;

    .main-content-container {
        padding: rem(15);
        display: flex;
        flex-direction: column;
        gap: rem(15);
        flex: 1 0 0px;
        min-width: 0;
        position: relative;

        &.main-content-container-dragged {
            &::before {
                content: 'Drop files here';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                border: 2px dashed $primary;
                margin: rem(15);
                background-color: rgba($color: #fff, $alpha: .8);
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: rem(30);
                color: $primary;
            }
        }

        .main-content-container-scroll {

            display: flex;
            flex-direction: column;
            height: 100%;

            .main-content-thumbnail {
                height: 90%;
                overflow: hidden;
                vertical-align: middle;
                cursor: pointer;
                border-radius: 3px;

                img {
                    object-fit: cover;
                    object-position: center;
                    width: 100%;
                    height: 100%;
                }
            }

            .main-content-description {
                height: 50%;

            }
        }

        .attachments-container {

            h6 {
                margin: 0;
                line-height: rem(28);
            }

            .attachments-amount {
                color: rgba(0, 42, 76, 0.4);
                line-height: rem(28);
            }

            .inline-input-wrapper {
                height: auto;
                border: none !important;

                svg {
                    color: $primary;
                    transition: color .3s;
                    height: rem(28);
                    width: rem(28);
                }

                &:hover svg {
                    color: darken($primary, 10);
                }
            }

            .inline-input-container {
                padding-bottom: 0;
            }

            .attachments-input-wrapper {
                height: auto;
                border: 1px dashed #475159 !important;
                border-radius: 4px;

                div {
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                    gap: rem(10);
                    padding: rem(12) rem(16);
                    color: #80929D;

                    p {
                        font-size: rem(14);
                        font-weight: 400;
                        line-height: 1;
                    }

                    svg {
                        transform: rotate(45deg);
                        font-size: rem(20);
                    }

                    &:hover {
                        color: $primary;
                    }
                }

                &:hover {
                    border-color: $primary !important;
                }
            }

            .attachments-input-container {
                padding-bottom: 0;
            }

            .scrollable-attachments {
                display: flex;
                gap: rem(10);
                height: fit-content;
            }

            .attachment {
                border-radius: rem(4);
                padding: rem(12);
                position: relative;
                width: rem(126);
                height: rem(80);
                min-width: rem(126);
                min-height: rem(80);
                background-color: rgba(66, 165, 245, 0.1);
                border: 1px solid rgba(0, 0, 0, 0.07);
                display: flex;
                justify-content: space-between;
                flex-direction: column;

                .attachment-information {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    height: 100%;

                    div {
                        border-radius: rem(4);
                        padding: rem(2) rem(8);
                        display: flex;
                        background: rgba(66, 165, 245, 0.5);
                        text-transform: uppercase;
                        color: $white;
                        font-weight: 500;
                        font-size: rem(12);
                        line-height: rem(17);
                        width: fit-content;
                    }

                    p {
                        width: 100%;
                        max-height: rem(32);
                        font-weight: 400;
                        font-size: rem(14);
                        line-height: rem(16);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                .attachment-actions {
                    display: none;
                    margin-bottom: rem(-8);
                    margin-left: rem(-8);
                    gap: rem(4);

                    button {
                        padding: rem(4);
                    }

                    svg {
                        color: #737577;
                        font-size: rem(20);
                    }
                }

                img {
                    display: none;
                }

                &:hover {
                    .attachment-information div {
                        display: none;
                    }

                    .attachment-actions {
                        display: flex;
                    }
                }
            }

            .image {
                background-color: rgba(125, 189, 54, 0.1);

                .attachment-information div {
                    background: rgba(125, 189, 54, 0.5);
                }
            }

            .pdf {
                background-color: rgba(227, 0, 0, 0.1);

                .attachment-information div {
                    background: rgba(227, 0, 0, 0.5);
                }
            }
        }

        h6 {
            font-weight: 500;
            margin-bottom: rem(5);
        }

        p {
            font-weight: 400;
        }
    }

    .side-content-container {
        width: rem(400);
        min-width: rem(400);
        background-color: #EBEBEB;
        padding: rem(15);

        .creation-action-container {
            button {
                padding: 0 rem(16);
                font-size: rem(12);
                line-height: rem(24);
                border-color: $blue;
                text-transform: capitalize;
                box-shadow: 0px 2px 5px rgba(0, 42, 76, 0.15);

                &.save-button {
                    background-color: $blue;
                    border: 1px solid lighten($blue, 20%);

                    &:disabled {
                        background-color: initial;
                    }

                    &:hover {
                        background-color: darken($blue, 10%);
                    }
                }

                &.cancel-button {
                    color: #666;

                    &:hover {
                        border-color: lighten($blue, 20%);
                        background-color: initial;
                    }
                }
            }
        }
    }

    .properties-table {
        width: 100%;
        border-bottom: 1px solid #cfcfcf;
        padding-bottom: rem(10);
        margin-bottom: rem(5);

        tr {
            display: flex;
            align-items: center;
            padding: rem(3.5) 0;

            &:first-of-type {
                padding-top: 0;
            }

            th {
                text-align: left;
                width: 30%;
                padding-right: rem(10);
                border-bottom: 1px solid transparent;

                p {
                    font-weight: 700;
                }
            }

            .property-selector {
                div {
                    padding: 0;
                    margin: 0;
                    min-height: auto;
                    height: auto;
                    width: 100%;
                    border-radius: 0;

                    p {
                        display: none;
                    }
                }

                div[role=button] {
                    color: $text-secondary;
                    font-weight: 400;
                    border-bottom: 1px solid transparent;
                    transition: all .1s;
                    text-transform: capitalize;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                svg {
                    display: none;
                }

                fieldset {
                    border: none;
                }

                div:first-of-type:hover {
                    div[role=button] {
                        color: $primary;
                    }
                }
            }

            td:first-of-type {
                width: 60%;
                padding-right: rem(10);
            }

            td:last-of-type {
                width: 10%;
                display: flex;
                justify-content: center;

                .property-circle {
                    width: rem(24);
                    height: rem(24);
                    border-radius: 50%;
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-position: center center;
                    background-size: contain;
                    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);

                    span {
                        line-height: 1;
                        text-transform: uppercase;
                        font-weight: 600;
                        font-size: rem(14);
                    }
                }

                img,
                .assignee-icon {
                    position: relative;
                    width: rem(24);
                    height: rem(24);
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-transform: uppercase;
                    font-weight: 500;
                    color: $white;
                }

                .assignee-icon::after {
                    content: "";
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background: linear-gradient(135deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .1) 100%);
                    border-radius: 50%;
                }
            }
        }
    }
}

.property-change-container {
    display: flex;
    align-items: center;
    color: #666;
    flex-wrap: wrap;

    span {
        text-transform: capitalize;
        font-size: rem(14);
    }

    &:not(:last-of-type) {
        margin-bottom: rem(5);
    }

    span:first-of-type {
        margin-right: rem(5);
    }

    span:nth-of-type(2) {
        color: rgba(102, 102, 102, 0.6);
    }

    svg {
        margin: 0 rem(4);
    }
}

.comment-input {
    width: 100%;
    background-color: $white;
    padding-right: 0 !important;

    fieldset {
        border: 1px solid #DDD;
    }
}


.open,
.normal {
    background-color: #E6F6CF;

    span,
    p {
        color: #4DA400;
    }
}

.active {
    background-color: #E0F1FB;

    span,
    p {
        color: #3D85C6;
    }
}

.in_progress {
    background-color: #A6E0FC;

    span,
    p {
        color: #444;
    }
}

.resolved {
    background-color: #0070E4;

    span,
    p {
        color: #FFF;
    }
}

.unset,
.closed,
.on_hold {
    background-color: rgba(204, 204, 204, 0.6);

    span,
    p {
        color: rgba(102, 102, 102, 0.6);
    }
}

.due,
.bug,
.show_stopper {
    background-color: #E30000;

    span,
    p {
        color: #FFF;
    }
}

.wish,
.critical {
    background-color: #FFC8EA;

    span,
    p {
        color: #444;
    }
}

.question,
.major {
    background-color: #FFEE9C;

    span,
    p {
        color: #B45F06;
    }
}

.task {
    background-color: #7DBD36;

    span,
    p {
        color: #FFF;
    }
}

.minor {
    background-color: #E6E6E6;

    span,
    p {
        color: #444;
    }
}
.AvatarButtonLeft {
    left: 0;
}

.AvatarButtonRight {
    right: 0;
}

.formInput {
    max-width: 100%;
    overflow: hidden;
}

.AvatarButton {
    opacity: 0;
    position: absolute;
    bottom: 0;
    text-align: center;
    vertical-align: middle;
    background-color: white;
    width: 50%;
    height: 100%;
    border: 0;
    cursor: pointer;
    transition: opacity 0.5s;
}

.Avatar {
    width: 100% !important;
    height: 100% !important;
    font-size: 250% !important;
}

.container:hover .AvatarButton {
    opacity: 0.5;
}

.container {
    position: relative;
}

.label {
    font-size: 14px;
    font-weight: 700;
    color: black;
    margin-bottom: 1px;
    text-transform: capitalize;
}

.phoneInput {
    width: 100% !important;
}

.matrix {
    display: grid;
    column-gap: 20px;
    width: 100%;
}

.row {
    grid-area: row;
}

.checkboxCategory {
    font-weight: bold;
    grid-column-start: 1;
}

.checkboxLabel + .checkboxCategory {
    margin-top: 16px !important;
}

.selectIcon {
    right: 48px !important;
}

.selectWithButton {
    padding-right: 0 !important;
}

.placeholder {
    color: rgba(0, 0, 0, 0.27) !important;
}

.textWithButton {
    padding-right: 0 !important;
}

.textFieldWithEnd {
    display: flex;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
}

.textFieldRootWithEnd fieldset {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.textFieldInputWithEnd {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.checkboxLabel {
    font-weight: normal;
    padding-left: 16px;
}

.column {
    grid-area: column;
}

.item {
    grid-area: item;
}

.imageControls {
    display: grid;
    grid-template-columns: 15% auto 15%;
}

.imageControls svg {
    margin: auto !important;
}

.imageActions {
    display: grid;
    grid-template-columns: 45% 45%;
    justify-content: space-evenly !important;
}

.imageActionsButton {
    width: 100%;
    padding: 8px;
}

.fileInput {
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    text-align: center;
    height: 37.12px;
    position: relative;
    cursor: pointer;
    transition: border 0.25s;
    border: 1px solid rgba(0, 0, 0, 0.24) !important;
}

.fileInput:hover {
    border: 1px solid rgba(0, 0, 0, 0.87) !important;
}

.fileInputDropping {
    border: 5px solid orange !important;
}

.fileInputIcon {
    float: inline-end;
    pointer-events: none;
}

.fileInputBox {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    flex-direction: column;
}

.fileName {
    padding-left: 8px;
    width: auto;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.fileInputContainer {
    padding-bottom: 8px;
}

.fileInputError {
    border: 1px solid rgba(255, 0, 0, 0.87) !important;
}

.textInput {
    position: relative;
}

.removeButton {
    width: 1rem;
    height: 1rem;
    position: absolute !important;
    right: 0;
    top: 0;
    transform: translateY(-50%);
    font-size: x-small !important;
}

.removeIcon {
    font-size: 1rem !important;
}
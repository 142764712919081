.container {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 0;

    .overlay {
        text-align: center;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        position: absolute;
        background: whitesmoke;
        flex-direction: column;
        gap: 5%;
        z-index: 2;

        .actions {
            display: flex;
            gap: 5%;
        }
    }
}

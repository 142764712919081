@import "styles/mixins.scss";
@import "styles/colors.module.scss";

.truck-creation-container {
    @include flex-grid(12, 15);
    row-gap: rem(15);

    .input-container {
        @include flex-grid-col(6, 12, 15);

        &.input-full-width {
            @include flex-grid-col(12, 12, 15);
        }

        div {
            height: auto;
        }

        label {
            color: $text-primary;
            font-size: rem(16);
            font-weight: 600;
            text-transform: capitalize;
            line-height: normal;
            margin-bottom: rem(7);
        }
    }
}
@import "styles/mixins.scss";
@import "styles/colors.module.scss";

.stacking-container {
    width: 100%;
    height: 100vh;
}

.resizable-x,
.resizable-y {
    display: flex;
    overflow: hidden;
}

.viewer-container {
    height: 100%;
    width: 100%;
}

.resizable-x {
    height: 100%;
}
.resizable-y {
    flex-direction: column;
    flex: 50%;
}

.resizer-x,
.resizer-y {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 42, 76, 0.1);
    font-size: rem(6);
    color: $white;
}

.resizer-x {
    z-index: 2;
    cursor: col-resize;
    width: rem(6);
}

.resizer-y {
    z-index: 1;
    cursor: row-resize;
    flex-direction: column;
}

.left-side-container,
.right-side-bottom-container,
.right-side-top-container {
    overflow: hidden;
}

.left-side-container {
    flex: 50%;
}

.right-side-top-container,
.right-side-bottom-container {
    flex: 50%;
}

.tabs-wrapper {
    border-bottom: 2px solid $border;
    margin: rem(10) 0;

    .tabs {
        min-height: 0;
        margin: 0 rem(20);

        .tabs-container {
            display: flex;
            gap: rem(20);

            button {
                border-bottom: 0;
                min-height: 0;
                padding: rem(5);
                margin-bottom: rem(5);

                svg {
                    margin-right: rem(7) !important;
                }
            }
        }
    }

    .tab-indicator {
        border-radius: 1px 1px 0px 0px;
    }
}

.tab-panel {
    height: calc(100% - rem(54.1));
}

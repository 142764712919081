@import "styles/mixins.scss";
@import "styles/colors.module.scss";

.data-grid-wrapper {
    min-height: rem(100);
    display: flex;
    height: 100%;
    width: 100%;

    .data-grid-root {
        border: 0;
    }

    .cell-header {

        &:focus,
        &:focus-within {
            outline: none !important;
        }
    }

    .cell {
        cursor: pointer;
        border-bottom: none !important;
        border-color: rgba(230, 234, 238, 0.8) !important;
        border-top: 1px solid;

        &:focus,
        &:focus-within {
            outline: none !important;
        }

        .user-avatar {
            width: rem(18);
            height: rem(18);
            border-radius: 50%;
            flex-shrink: 0;

            p {
                color: $white;
                font-size: rem(12);
                margin: 0;
                padding: 0;
            }
        }

        .cell-content {
            font-size: rem(15);
            font-weight: 400;
            text-transform: capitalize;
        }

        .cell-content-sub {
            display: flex;
            gap: rem(5);
            align-items: center;

            p,
            svg {
                color: $text-alternate;
                font-size: rem(11.5);
                font-weight: 400;
                line-height: 1;
                text-transform: capitalize;
            }
        }

        .truck-layout {
            color: $text-link;
            font-weight: 400;
            border-bottom: 1px solid transparent;
            font-size: rem(14);
            transition: .3s border-color;

            &:hover {
                border-color: $text-link;
            }
        }

        .location-cell {
            p {
                color: $text-primary;
                font-size: rem(14);
                font-weight: 400;
            }

            svg {
                color: $text-alternate;
                font-size: rem(10);
            }
        }

        .force-ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .data-grid-footer {
        border-top: none !important;
    }
}